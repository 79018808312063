(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name neo.controller:ErrorToastCtrl
   *
   * @description
   *
   */
  angular
  .module('neo')
  .controller('ErrorToastCtrl', ErrorToastCtrl);

  function ErrorToastCtrl($rootScope, $mdToast, $filter, $state) {
    var vm = this;
    vm.ctrlName = 'ErrorToastCtrl';
    $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
      $mdToast.show(
        $mdToast.simple()
        .textContent($filter('translate')('STATE_CHANGE_ERROR'))
        .position('bottom left')
        .hideDelay(3000)
      );
      //$state.go(fromState.name,{}, {reload:true});
    });

  }
}());
